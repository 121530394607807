import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';

import './impressum.scss';

const DatenschutzPage = () => {
  const { company, privacy } = useStaticQuery(graphql`
    query {
      privacy {
        imprint
      }
      company {
        name
        phone
        postal_code
        address_line_1
        mail
        city
        contact_first_name
        contact_last_name
        licenses
      }
    }
  `);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="impressum-header">Impressum - Angaben gemäß § 5 TMG</div>
      <div style={{ width: '1280px', margin: '0 auto', marginTop: '80px' }}>
        <div className="impressum-text-header">Verteren durch</div>

        <div className="impressum-text">
          {company.name}
          {' '}
          <br />
          {company.address_line_1}
          {' '}
          <br />
          {company.postal_code}
          {' '}
          {company.city}
        </div>

        <div className="impressum-text-header">Kontakt</div>

        <div className="impressum-text">
          Telefon:
          {' '}
          {company.phone}
          {' '}
          <br />
          E-Mail:
          {' '}
          {company.mail}
        </div>

        <div className="impressum-text-header">Vertreten durch</div>

        <div className="impressum-text">
          {company.contact_first_name}
          {' '}
          {company.contact_last_name}
        </div>

        <div className="impressum-text-header">Bildnachweis</div>

        {company.licenses.length && (
          <div className="impressum-text">
            <ul>
              {company.licenses.map((l) => (
                <li>{l}</li>
              ))}
            </ul>
          </div>
        )}

        <ReactMarkdown source={privacy.imprint} />
      </div>
    </Layout>
  );
};

export default DatenschutzPage;
